import React from 'react';
import person from '../../../../assets/images/person.png'

const Testmonials = () => {
    return (
        <div className='container text-center'>
            <h2 className='text-warning fw-bold'>Testmonials</h2>
           <div className='text-center mb-5'> <h1 style={{ borderBottom: '3px solid #ffe400' }} className='d-inline'>What Customers Say</h1></div>
            <div className='row'>
            <div className='col-12 col-md-6 col-lg-4'>
            <div className='shadow-lg p-3 mb-5 bg-body text-center'>
                <img width='150px' className='rounded-circle' src={person} alt="" />
                <h5>Nazmul Huda</h5>
                <h6>Owner of <span className='fw-bold text-warning'>NBC Firm</span></h6>
                <p>Clear Softwares Jahid make my life easy with his account related experiences and digital knowledge also honesty.At now I almost depended on him for observing my businesses.</p>
            </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
            <div className='shadow-lg p-3 mb-5 bg-body rounded text-center'>
                <img width='150px' className='rounded-circle' src={person} alt="" />
                <h5>MD Razibillah Razib</h5>
                <h6>Owner of <span className='fw-bold text-warning'>M/s Sawda Enterprise</span></h6>
                <p>Clear Softwares Jahid Vai accounts related knowledge sometimes surprize me a lot.Age is not that high still a student but he make my business accounts simple and painless.</p>
            </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
            <div className='shadow-lg p-3 mb-5 bg-body rounded text-center'>
                <img width='150px' className='rounded-circle' src={person} alt="" />
                <h5>Suhel Mahmud</h5>
                <h6>Owner of <span className='fw-bold text-warning'>Seja Enterprise</span></h6>
                <p>Iam working with Jahid last few years. He really makes accounts easy with his powerfull computer knowledge and experienses. I Wish Clear Softwares Jahid growth in market so that people can benifit.</p>
            </div>
            </div>
            </div>
            
        </div>
    );
};

export default Testmonials;